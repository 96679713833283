import GapiOauth2Service from "@learnics/services/src/gapiOauth2";
import { DEFAULT_SCOPES } from "@learnics/services/src/utils/gapiScopes";
import { validateGapiTokenForScopes } from "@/utils/gapi/validateGapiTokenForScopes";
import { auth } from "@learnics/services/src/analyticsConfig";

/**
 * A wrapper around GoogleClassroomService that handles authorization
 * for each of the API calls - specifically for learnics-app.
 */
export async function validateAndRefreshTokenIfNeeded(extraScopes = []) {
  let gapiTokens = JSON.parse(localStorage.getItem("gapiTokens") || "{}");
  let gapiToken = gapiTokens[auth.currentUser.uid];
  const validationResult = await validateGapiTokenForScopes(
    [...DEFAULT_SCOPES, ...extraScopes],
    auth.currentUser.uid,
    gapiToken
  );

  if (validationResult.shouldRefresh) {
    try {
      gapiToken = await GapiOauth2Service.refreshGapiOauth2AccessToken();

      gapiTokens = JSON.parse(localStorage.getItem("gapiTokens") || "{}");
      gapiTokens[auth.currentUser.uid] = gapiToken;
      localStorage.setItem("gapiTokens", JSON.stringify(gapiTokens));
      validationResult.shouldRefresh = false;
    } catch (error) {
      console.error(error);
    }
  }

  if (
    validationResult.missingScopes.length === 0 &&
    !validationResult.shouldRefresh
  ) {
    console.warn("Loading access token info to ensure the token is valid...");
    try {
      const accessTokenInfo = await GapiOauth2Service.getAccessTokenInfo(
        gapiToken.access_token
      );
      // This means we have successfully validated the token.
      console.log("Access token info:", accessTokenInfo);
    } catch (error) {
      console.error(error);
      validationResult.shouldRefresh = true;
    }
  }

  if (
    validationResult.missingScopes.length > 0 ||
    validationResult.shouldRefresh
  ) {
    throw new Error("Missing scopes");
  }
  return gapiToken;
}
