import startAssignmentRoute from "./start-assignment/route";
import studentSubmissionsRoute from "./submissions/route";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "student",
  name: "StudentAssignmentRoot",
  component: lazyLoad("StudentAssignmentRoot"),
  props: true,
  children: [
    {
      path: "",
      name: "StudentAssignmentPortal",
      component: lazyLoad("StudentAssignmentPortal"),
    },
    startAssignmentRoute,
    studentSubmissionsRoute,
  ],
};
