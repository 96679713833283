<template>
  <div class="learnics-app">
    <ExtensionLoader>
      <router-view></router-view>
    </ExtensionLoader>
  </div>
</template>

<script>
import ExtensionLoader from "@/ExtensionLoader.vue";

export default {
  name: "App",
  components: { ExtensionLoader },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Targeted Learning Analytics for Your Classroom",
    // all titles will be injected into this template
    titleTemplate: "Learnics | %s",
  },
};
</script>

<style lang="scss">
.learnics-app .v-application {
  font-family: "Open Sans", sans-serif !important;
  .headline {
    // To pin point specific classes of some components
    font-family: "Raleway", sans-serif !important;
  }
  a {
    text-decoration: none;
  }
  .body-2 {
    font-family: "Open Sans", sans-serif !important;
  }
}
.v-data-table td {
  font-size: 14px;
  height: 48px;
}
.v-data-table td,
.v-data-table th {
  padding: 0 16px;
}
.google-visualization-tooltip {
  pointer-events: none;
}

.min-width-0 {
  min-width: 0 !important;
}

.learnics-app .v-application {
  .title,
  .subtitle-1,
  .display-1,
  .display-2 {
    // To pin point specific classes of some components
    font-family: "Raleway", sans-serif !important;
  }
}

.raleway {
  font-family: "Raleway", sans-serif !important;
}
.open-sans {
  font-family: "Open Sans", sans-serif !important;
}
.min-width-0 {
  min-width: 0 !important;
}

.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}
.gap-6 {
  gap: 24px;
}
.gap-7 {
  gap: 28px;
}
.gap-8 {
  gap: 32px;
}
.gap-9 {
  gap: 36px;
}
.gap-10 {
  gap: 40px;
}
.gap-11 {
  gap: 44px;
}
.gap-12 {
  gap: 48px;
}
.gap-13 {
  gap: 52px;
}
.gap-14 {
  gap: 56px;
}
.gap-15 {
  gap: 60px;
}
.gap-16 {
  gap: 64px;
}

.col-gap-1 {
  column-gap: 4px;
}
.col-gap-2 {
  column-gap: 8px;
}
.col-gap-3 {
  column-gap: 12px;
}
.col-gap-4 {
  column-gap: 16px;
}
.col-gap-5 {
  column-gap: 20px;
}
.col-gap-6 {
  column-gap: 24px;
}
.col-gap-7 {
  column-gap: 28px;
}
.col-gap-8 {
  column-gap: 32px;
}
.col-gap-9 {
  column-gap: 36px;
}
.col-gap-10 {
  column-gap: 40px;
}
.col-gap-11 {
  column-gap: 44px;
}
.col-gap-12 {
  column-gap: 48px;
}
.col-gap-13 {
  column-gap: 52px;
}
.col-gap-14 {
  column-gap: 56px;
}
.col-gap-15 {
  column-gap: 60px;
}
.col-gap-16 {
  column-gap: 64px;
}

.row-gap-1 {
  row-gap: 4px;
}
.row-gap-2 {
  row-gap: 8px;
}
.row-gap-3 {
  row-gap: 12px;
}
.row-gap-4 {
  row-gap: 16px;
}
.row-gap-5 {
  row-gap: 20px;
}
.row-gap-6 {
  row-gap: 24px;
}
.row-gap-7 {
  row-gap: 28px;
}
.row-gap-8 {
  row-gap: 32px;
}
.row-gap-9 {
  row-gap: 36px;
}
.row-gap-10 {
  row-gap: 40px;
}
.row-gap-11 {
  row-gap: 44px;
}
.row-gap-12 {
  row-gap: 48px;
}
.row-gap-13 {
  row-gap: 52px;
}
.row-gap-14 {
  row-gap: 56px;
}
.row-gap-15 {
  row-gap: 60px;
}
.row-gap-16 {
  row-gap: 64px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
