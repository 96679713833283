import UserService from "@learnics/services/src/user";
import Vue from "vue";

/**
 * A simple module for storing user data, specifically documents at users/{uid}.
 *
 * This module may be used to store user data for any user, not just the current user.  It should be just loaded in
 * and left alone, unless you know a user's data has changed, in which case it should be updated.  Otherwise, feel
 * free to use the UserService to cache user document data, and reference it using the users map.
 *
 */

export const state = {
  users: {},
};

export const getters = {
  getUsers: (state) => {
    return state.users;
  },
};

export const mutations = {
  addUser(state, payload) {
    const k = payload.id || payload.uid;
    Vue.set(state.users, k, JSON.parse(JSON.stringify(payload)));
  },
  removeUser(state, payload) {
    Vue.delete(state.users, payload.id || payload.uid);
  },
};

export const actions = {
  async loadUser({ state, commit, rootState }, args = { forced: false }) {
    const uid = args.uid;
    if (!uid) throw new Error("No uid provided");
    if (!args.forced && state.users[uid]) return;
    const loadingUser = { name: "Loading... ", uid: uid, id: uid };
    commit("addUser", loadingUser);
    try {
      const userData = await UserService.getUserDocument(uid);
      if (!userData) {
        commit("removeUser", loadingUser);
        throw new Error("User #" + uid + " not found");
      }
      commit("addUser", userData);
    } catch (err) {
      console.log("Error loading user:", err);
      throw err;
    }
  },
  async loadUsers({ state, commit, rootState }, args = { forced: false }) {
    const callback = args.callback || (async () => {});
    const uids = args.uids;
    if (!uids) throw new Error("No uids provided");
    const uidsToLoad = [];
    for (let uid of uids) {
      if (
        !args.forced &&
        state.users[uid] &&
        state.users[uid].name !== "Loading... "
      ) {
        await callback(state.users[uid], false);
        continue;
      }
      const loadingUser = { name: "Loading... ", uid: uid, id: uid };
      commit("addUser", loadingUser);
      uidsToLoad.push(uid);
    }
    console.log("Loading users:", uidsToLoad);
    for (let uid of uidsToLoad) {
      const loadingUser = { uid: uid, id: uid };
      try {
        const userData = await UserService.getUserDocument(uid);
        if (!userData) {
          commit("removeUser", loadingUser);
          // throw new Error("User #" + uid + " not found");
        } else {
          commit("addUser", userData);
          await callback(userData, true);
        }
      } catch (err) {
        console.log("Error loading user:", err);
        commit("removeUser", loadingUser);
        await callback(loadingUser, true);
      }
    }
  },
};
