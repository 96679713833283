import GoogleDriveService from "@learnics/services/src/googleDrive";

import Vue from "vue";

export const state = {
  googleDriveFiles: {},
  studentCopyDriveFiles: {},
};

export const getters = {
  getStudentCopyDriveFiles: (state) => state.studentCopyDriveFiles,
  getGoogleDriveFiles: (state) => state.googleDriveFiles,
};

export const mutations = {
  setStudentCopyDriveFile(state, payload) {
    if (!payload) throw new Error("Payload is null");
    const { assignmentId, userId, driveFileId, data } = payload;
    if (!assignmentId) throw new Error("Payload.assignmentId is null");
    if (!userId) throw new Error("Payload.userId is null");
    if (!driveFileId) throw new Error("Payload.driveFileId is null");
    if (
      !data &&
      state.studentCopyDriveFiles[assignmentId]?.[userId]?.[driveFileId]
    ) {
      Vue.delete(
        state.studentCopyDriveFiles[assignmentId][userId],
        driveFileId
      );
      return;
    }
    if (!state.studentCopyDriveFiles[assignmentId]) {
      Vue.set(state.studentCopyDriveFiles, assignmentId, {});
    }
    if (!state.studentCopyDriveFiles[assignmentId][userId]) {
      Vue.set(state.studentCopyDriveFiles[assignmentId], userId, {});
    }
    Vue.set(
      state.studentCopyDriveFiles[assignmentId][userId],
      driveFileId,
      data
    );
  },
  setGoogleDriveFile(state, payload) {
    if (!payload) throw new Error("Payload is null");
    const { id, data } = payload;
    if (!id) throw new Error("Payload.id is null");
    if (!data && state.googleDriveFiles[id]) {
      Vue.delete(state.googleDriveFiles, id);
      return;
    }
    Vue.set(state.googleDriveFiles, id, data);
  },
};

export const actions = {
  async loadStudentCopyDriveFiles(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    const assignmentId = args.assignmentId;
    if (!assignmentId) throw new Error("No assignmentId provided");
    const userId = args.userId || null;

    if (!args.forced) {
      if (state.studentCopyDriveFiles[assignmentId] && !userId) return;
      if (userId && state.studentCopyDriveFiles[assignmentId]?.[userId]) return;
    }
    try {
      let copies = await GoogleDriveService.getStudentCopyDriveFiles(
        assignmentId,
        userId
      );
      const copyIds = Object.keys(copies);
      console.log("Retrieved student copy drive files:", copies);
      for (let i = 0; i < copyIds.length; i++) {
        const copy = copies[copyIds[i]];
        try {
          if (!state.googleDriveFiles[copy.driveFileId] || args.forced) {
            const driveFile = await GoogleDriveService.getFile(
              copy.driveFileId
            );
            commit("setGoogleDriveFile", {
              id: copy.driveFileId,
              data: driveFile,
            });
          }
          commit("setStudentCopyDriveFile", {
            assignmentId,
            userId: copy.userId,
            driveFileId: copy.id,
            data: copy,
          });
        } catch (e) {
          console.log(
            "Error getting google drive file for student copy drive file: " + e
          );
        }
      }
    } catch (e) {
      console.log("Error getting student copy drive files: " + e);
    }
  },
  async loadGoogleDriveFile(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    const id = args.id;
    if (!id) throw new Error("No id provided");

    if (!args.forced && state.googleDriveFiles[id]) return;
    try {
      const driveFile = await GoogleDriveService.getFile(id);
      commit("setGoogleDriveFile", {
        id,
        data: driveFile,
      });
    } catch (e) {
      console.log("Error getting google drive file: " + e);
    }
  },
};
