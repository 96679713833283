import { getAllExtensionControllers } from "@/services/extensionControllers";

/**
 * A class to store the tab ID of the current tab.  This is used to communicate
 * with any extensions that may be running.
 */
class MyTabId {
  constructor() {
    this.initialized = false;
    this.tabId = null;
  }

  async load(forced = false) {
    if (this.initialized && !forced && this.tabId !== null) return this.tabId;
    const extensionControllers = getAllExtensionControllers().filter(
      (extension) => !!extension.manifest
    );
    for (let i = 0; i < extensionControllers.length; i++) {
      const extensionController = extensionControllers[i];
      try {
        const tabIdResponse = await extensionController.sendMessage({
          action: "getTabId",
        });
        this.tabId = tabIdResponse.data;
        this.initialized = true;
        return this.tabId;
      } catch (e) {
        console.error("Error getting tab ID", e);
      }
    }
    return null;
  }

  get() {
    if (!this.initialized) throw new Error("MyTabId not initialized");
    return this.tabId;
  }
}

export const myTabId = new MyTabId();
