export const state = {
  ltiContext: null,
};

export const getters = {
  getLtiContext: (state) => {
    return state.ltiContext;
  },
};

export const mutations = {
  setLtiContext(state, payload) {
    state.ltiContext = payload;
  },
};

export const actions = {};
