import OrganizationService from "@learnics/services/src/organization";
import Vue from "vue";
import {
  administratorRoles,
  studentRoles,
  teacherRoles,
} from "@learnics/models/src/utils/roles";

export const state = {
  organizations: {},
  currentOrgId: null,
  areOrgsLoaded: false,
  areOrgsLoading: 0,
};

export const getters = {
  getOrganizations: (state) => {
    return state.organizations;
  },
  getCurrentOrgId: (state) => {
    return state.currentOrgId;
  },
  getAreOrgsLoaded: (state) => {
    return state.areOrgsLoaded;
  },
  getAreOrgsLoading: (state) => {
    return state.areOrgsLoading;
  },

  // ******* COMPUTED PROPERTY GETTERS ******* DO NOT TRY TO MUTATE THESE VALUES ********
  getCurrentOrganization: (state) => {
    return state.currentOrgId && state.organizations[state.currentOrgId];
  },
};

export const mutations = {
  setOrganizations(state, payload) {
    state.organizations = payload;
  },

  setAreOrgsLoaded(state, payload) {
    Vue.set(state, "areOrgsLoaded", payload);
  },

  setAreOrgsLoading(state, payload) {
    Vue.set(
      state,
      "areOrgsLoading",
      payload ? state.areOrgsLoading + 1 : state.areOrgsLoading - 1
    );
  },

  updateOrganizationRoles(state, payload) {
    const { id, role, uids } = payload;
    const org = state.organizations[id];
    if (!org) throw new Error("Organization not found in store.");
    org.roles[role] = uids || [];
  },

  addOrganization(state, payload) {
    if (!payload)
      throw new Error(
        "Error adding organization to store. Provided organization is null."
      );
    if (!payload.id)
      throw new Error(
        "Error adding organization to store. Provided organization has no valid id variable."
      );
    Vue.set(state.organizations, payload.id, payload);
  },

  setCurrentOrgId(state, payload) {
    Vue.set(state, "currentOrgId", payload);
  },
};

export const actions = {
  async loadOrganization(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    if (!args.orgId)
      throw new Error(
        "No organization id provided to loadOrganization action."
      );
    if (state.organizations[args.orgId] && !args.forced) return;
    const org = await OrganizationService.getOrganization(args.orgId);
    commit("addOrganization", org);
  },
  async loadOrganizations(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    if ((state.areOrgsLoaded || state.areOrgsLoading) && !args.forced) return;
    commit("setAreOrgsLoading", true);
    try {
      const roles = [...teacherRoles, ...studentRoles, ...administratorRoles];
      for (const role of roles) {
        await OrganizationService.getOrganizations(
          rootState.auth.user.uid,
          role,
          async (org) => {
            commit("addOrganization", org);
          }
        );
      }
      commit("setAreOrgsLoaded", true);
    } catch (error) {
      console.log("Error loading organizations", error);
    } finally {
      commit("setAreOrgsLoading", false);
    }
  },
};
