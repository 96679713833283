export function openGoogleApiLoginPopup(args = {}, customToken) {
  let targetUrl =
    window.location.origin +
    `/googleLogin${args.state?.showWarning ? "Warning" : ""}?args=` +
    encodeURIComponent(JSON.stringify(args));

  if (customToken) {
    targetUrl += `&custom_token=${encodeURIComponent(customToken)}`;
  }
  console.log("Target url = ", targetUrl);
  return popupCenter(targetUrl, "Google Authorization", 520, 622);
}
function popupCenter(url, title, w, h) {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const newWindow = window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  if (newWindow?.focus) newWindow.focus();
  return newWindow;
}
