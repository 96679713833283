import {
  oneClickController,
  studentLinkController,
  teacherLinkController,
} from "@/services/extensionControllers";
import Vue from "vue";

export const state = {
  loggers: {},
  extensionManifests: {},
  extensionSelectedSessionId: null,
};

export const getters = {
  getStudentLinkLogger: (state) => {
    return state.loggers[studentLinkController.extensionId];
  },
  getOneClickLogger: (state) => {
    return state.loggers[oneClickController.extensionId];
  },
  getExtensionManifests: (state) => {
    return state.extensionManifests;
  },
  getStudentLinkManifest: (state) => {
    return state.extensionManifests[studentLinkController.extensionId];
  },
  getOneClickManifest: (state) => {
    return state.extensionManifests[oneClickController.extensionId];
  },
  getClassroomProManifest: (state) => {
    return state.extensionManifests[teacherLinkController.extensionId];
  },
  getExtensionSelectedSessionId: (state) => {
    return state.extensionSelectedSessionId;
  },
};

export const mutations = {
  setLogger(state, payload) {
    Vue.set(state.loggers, payload.extensionId, payload.logger);
  },
  setExtensionManifest(state, payload) {
    Vue.set(state.extensionManifests, payload.extensionId, payload.manifest);
  },
  setExtensionSelectedSessionId(state, sessionId) {
    state.extensionSelectedSessionId = sessionId;
  },
};

export const actions = {};
