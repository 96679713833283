function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "/note-boards",
  name: "NoteBoardsRoot",
  component: lazyLoad("NoteBoardsRoot"),
  redirect: "./",
  props: true,
  children: [
    {
      path: "",
      name: "NoteBoardsPortal",
      component: lazyLoad("NoteBoardsPortal"),
      props: true,
    },
    {
      path: "session",
      component: lazyLoad("NoteBoardsSessionsRoot"),
      name: "NoteBoardsSessionsRoot",
      props: true,
      children: [
        {
          path: ":sessionId",
          component: lazyLoad("NoteBoardsSessionRoot"),
          props: true,
          children: [
            {
              path: "",
              name: "NoteBoardsSessionPortal",
              component: lazyLoad("NoteBoardsSessionPortal"),
              props: true,
            },
            {
              path: "create",
              name: "NoteBoardsSessionCreateView",
              component: lazyLoad("NoteBoardsSessionCreateView"),
              props: true,
            },
            {
              path: "select",
              name: "NoteBoardsSessionSelectView",
              component: lazyLoad("NoteBoardsSessionSelectView"),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "list",
      name: "NoteBoardsView",
      component: lazyLoad("NoteBoardsView"),
      props: true,
    },
    {
      path: ":noteBoardId",
      component: lazyLoad("NoteBoardRoot"),
      props: true,
      children: [
        {
          path: "",
          name: "NoteBoardPortal",
          component: lazyLoad("NoteBoardPortal"),
          props: true,
        },
        {
          path: "home",
          name: "NoteBoardView",
          component: lazyLoad("NoteBoardView"),
          props: true,
        },
      ],
    },
  ],
};
