import teacherSubmissionsRoute from "./submissions/route";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "teacher",
  name: "TeacherAssignmentRoot",
  component: lazyLoad("TeacherAssignmentRoot"),
  props: true,
  children: [
    {
      path: "",
      name: "TeacherAssignmentPortal",
      component: lazyLoad("TeacherAssignmentPortal"),
    },
    teacherSubmissionsRoute,
  ],
};
