function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "assignment/:assignment_id",
  props: true,
  component: lazyLoad("AssignmentPopupRoot"),
  children: [
    {
      path: "instructorStopLogging",
      name: "InstructorStopLoggingView",
      props: true,
      component: lazyLoad("InstructorStopLoggingView"),
    },
  ],
};
