function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "student/:studentId",
  name: "IndividualStudentRoot",
  component: lazyLoad(`IndividualStudentRoot`),
  props: true,
  children: [
    {
      path: "",
      name: "IndividualStudentPortal",
      component: lazyLoad(`IndividualStudentPortal`),
      props: true,
    },
    {
      path: "data",
      name: "IndividualStudentDataView",
      component: lazyLoad(`IndividualStudentDataView`),
      props: true,
    },
    {
      path: "online-activity",
      name: "IndividualStudentVisualizationsView",
      component: lazyLoad(`IndividualStudentVisualizationsView`),
      props: true,
    },
    {
      path: "ai-and-integrity",
      name: "IndividualStudentAIAndIntegrityView",
      component: lazyLoad(`IndividualStudentAIAndIntegrityView`),
      props: true,
    },
    {
      path: "notes-tags-annotations",
      name: "IndividualStudentNotesTagsAnnotationsView",
      component: lazyLoad(`IndividualStudentNotesTagsAnnotationsView`),
      props: true,
    },
  ],
};
