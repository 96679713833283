import { sectionBreadcrumbs } from "./routeBreadcrumbs";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "sections/:sectionId",
  name: "SectionRoot",
  component: lazyLoad("SectionRoot"),
  meta: {
    breadcrumbs: sectionBreadcrumbs,
  },
  props: true,
  children: [
    {
      name: "SectionPortal",
      path: "",
      redirect: "./assignments",
      props: true,
    },
    {
      path: "settings",
      name: "SectionSettingsView",
      component: lazyLoad("SectionSettingsView"),
      props: true,
    },
    {
      path: "roster",
      name: "SectionRosterView",
      component: lazyLoad("SectionRosterView"),
      props: true,
    },
    {
      path: "assignments",
      name: "SectionAssignmentsRoot",
      component: lazyLoad("SectionAssignmentsRoot"),
      props: true,
      redirect: "./",
      children: [
        {
          path: "",
          name: "SectionAssignmentsView",
          component: lazyLoad("SectionAssignmentsView"),
          props: true,
        },
      ],
    },
  ],
};
