import GoogleClassroomService from "@learnics/services/src/googleClassroom";
import { authorizeGapiScopes } from "@/utils/gapi/authorizeGapiScopes";
import { DEFAULT_SCOPES } from "@learnics/services/src/utils/gapiScopes";
import { validateAndRefreshTokenIfNeeded } from "@/utils/gapi/validateAndRefreshTokenIfNeeded";

async function validateHelper(scopes, validationOnly = false) {
  let gapiToken;
  if (validationOnly) {
    gapiToken = await validateAndRefreshTokenIfNeeded([...scopes]);
  } else {
    gapiToken = await authorizeGapiScopes([...DEFAULT_SCOPES, ...scopes]);
  }
  return gapiToken;
}

/**
 * A wrapper around GoogleClassroomService that handles authorization
 * for each of the API calls - specifically for learnics-app.
 */
export default {
  async listCourses(validationOnly = false) {
    const gapiToken = await validateHelper(
      ["https://www.googleapis.com/auth/classroom.courses.readonly"],
      validationOnly
    );
    return await GoogleClassroomService.listCourses(gapiToken.access_token);
  },

  async createCourseWork(courseId, courseworkObject, validationOnly = false) {
    const gapiToken = await validateHelper(
      ["https://www.googleapis.com/auth/classroom.coursework.students"],
      validationOnly
    );
    return await GoogleClassroomService.createCourseWork(
      gapiToken.access_token,
      courseId,
      courseworkObject
    );
  },
  async createCourseOrSection(
    aliasId,
    courseName,
    sectionName,
    validationOnly = false
  ) {
    const gapiToken = await validateHelper(
      ["https://www.googleapis.com/auth/classroom.courses"],
      validationOnly
    );
    return await GoogleClassroomService.createCourse(
      gapiToken.access_token,
      aliasId,
      courseName,
      sectionName
    );
  },
  async updateCourseWork(
    courseId,
    courseworkId,
    updateMask,
    courseworkObject,
    validationOnly = false
  ) {
    const gapiToken = await validateHelper(
      ["https://www.googleapis.com/auth/classroom.coursework.students"],
      validationOnly
    );
    return await GoogleClassroomService.updateCourseWork(
      gapiToken.access_token,
      courseId,
      courseworkId,
      updateMask,
      courseworkObject
    );
  },

  async listCourseWork(courseId, validationOnly = false) {
    const scopes = [
      ...DEFAULT_SCOPES,
      [
        "https://www.googleapis.com/auth/classroom.coursework.students.readonly",
        "https://www.googleapis.com/auth/classroom.student-submissions.students.readonly",
        "https://www.googleapis.com/auth/classroom.coursework.students",
      ],
    ];
    const gapiToken = await validateHelper(scopes, validationOnly);
    return await GoogleClassroomService.listCourseWork(
      gapiToken.access_token,
      courseId
    );
  },
  async registerCourseworkPushNotifications(courseId, validationOnly = false) {
    const gapiToken = await validateHelper(
      ["https://www.googleapis.com/auth/classroom.push-notifications"],
      validationOnly
    );
    return await GoogleClassroomService.registerCourseworkPushNotifications(
      gapiToken.access_token,
      courseId
    );
  },

  async getCourseFromClassroomUrl(
    courseUrl,
    courses = null,
    validationOnly = false
  ) {
    if (!courses) {
      try {
        courses = await this.listCourses(validationOnly);
      } catch (err) {
        console.log("Error getting courses from classroom URL", err);
        return null;
      }
    }
    const splitRegex = /(https?:\/\/)(.*?)(\/.*)/;
    const endOfUrl = splitRegex.exec(courseUrl).pop();
    const possibilities = endOfUrl.split("/").filter((i) => {
      return i.length > 3;
    });
    const courseIdentifiers = possibilities.reverse();

    let course = null;
    for (let i = 0; i < courseIdentifiers.length && !course && courses; i++) {
      course = courses.find(
        (c) => c.alternateLink.indexOf(courseIdentifiers[i]) >= 0
      );
    }
    return course;
  },
};
