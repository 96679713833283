import AssignmentService from "@learnics/services/src/assignment";
import Vue from "vue";

export const state = {
  assignments: {},
  assignmentDetails: {},
  assignmentKeys: {},
  currentAssignmentId: null,
  assignmentFormModel: null,
  areAssignmentsLoadedForCourse: {},
  areAssignmentsLoadingForCourse: {},
  areAssignmentsLoadedForSection: {},
  areAssignmentsLoadingForSection: {},
  areSharedAssignmentsLoadedForOrganization: {},
  areSharedAssignmentsLoadedForUser: false,
  areKeysLoadedForAssignment: {},
};

export const getters = {
  getAssignments: (state) => state.assignments,
  getAssignmentDetails: (state) => state.assignmentDetails,
  getAssignmentKeys: (state) => state.assignmentKeys,
  getAssignmentFormModel: (state) => state.assignmentFormModel,

  getAreAssignmentsLoadedForCourse: (state) =>
    state.areAssignmentsLoadedForCourse,
  getAreAssignmentsLoadingForCourse: (state) =>
    state.areAssignmentsLoadingForCourse,
  getAreAssignmentsLoadingForSection: (state) =>
    state.areAssignmentsLoadingForSection,
  getAreAssignmentsLoadedForSection: (state) =>
    state.areAssignmentsLoadedForSection,

  getAreSharedAssignmentsLoadedForOrganization: (state) =>
    state.areSharedAssignmentsLoadedForOrganization,

  getAreKeysLoadedForAssignment: (state) => state.areKeysLoadedForAssignment,

  getCurrentAssignmentId: (state) => {
    return state.currentAssignmentId;
  },

  // ******* COMPUTED PROPERTY GETTERS ******* DO NOT TRY TO MUTATE THESE VALUES ********

  getCurrentAssignmentDetail: (state, getters, rootState) => {
    return (
      getters.getCurrentAssignment?.assignmentDetailId &&
      state.assignmentDetails[getters.getCurrentAssignment.assignmentDetailId]
    );
  },
  getCurrentAssignment: (state) => {
    return (
      state.currentAssignmentId && state.assignments[state.currentAssignmentId]
    );
  },
  getCurrentAssignmentStudentIds: (state, getters, rootState, rootGetters) => {
    const currentCourse = rootGetters.getCurrentCourse;
    const currentSection = rootGetters.getCurrentSection;
    return currentSection?.roles.Learner || currentCourse?.roles.Learner || [];
  },
  getCurrentAssignmentSessionIds: (state, getters) => {
    const studentIds = getters.getCurrentAssignmentStudentIds;
    const result = [];
    for (let studentId of studentIds) {
      const assignmentKey =
        state.assignmentKeys[getters.getCurrentAssignmentId]?.[studentId];
      if (!assignmentKey) continue;
      result.push(assignmentKey);
    }
    return result;
  },
  getCurrentCourseAssignments: (state, getters, rootState) => {
    return Object.keys(state.assignments)
      .filter(
        (assignId) =>
          state.assignments[assignId].courseId + "" ===
          rootState.courses.currentCourseId + ""
      )
      .map((assignId) => state.assignments[assignId])
      .sort((a, b) => {
        return b.created - a.created;
      });
  },
  getCurrentSectionAssignments: (state, getters, rootState) => {
    return Object.keys(state.assignments)
      .filter(
        (assignId) =>
          state.assignments[assignId].sectionId + "" ===
          rootState.sections.currentSectionId + ""
      )
      .map((assignId) => state.assignments[assignId])
      .sort((a, b) => {
        return b.created - a.created;
      });
  },
};

export const mutations = {
  addAssignment(state, payload) {
    Vue.set(state.assignments, payload.id, payload);
  },
  addAssignmentKey(state, payload) {
    if (!payload.key) throw new Error("Payload.key is null");
    if (!payload.uid) throw new Error("Payload.uid is null");
    if (!payload.assignmentId) throw new Error("Payload.assignmentId is null");

    if (!state.assignmentKeys[payload.assignmentId]) {
      Vue.set(state.assignmentKeys, payload.assignmentId, {});
    }

    Vue.set(
      state.assignmentKeys[payload.assignmentId],
      payload.uid,
      payload.key
    );
  },

  updateAssignmentRoles(state, payload) {
    const { id, role, uids } = payload;
    const assignment = state.assignments[id];
    if (!assignment) throw new Error("Assignment not found in store.");
    assignment.roles[role] = uids || [];
  },
  updateAssignmentDetailRoles(state, payload) {
    const { id, role, uids } = payload;
    const assignmentDetail = state.assignmentDetails[id];
    if (!assignmentDetail) throw new Error("AssignmentDetail found in store.");
    assignmentDetail.roles[role] = uids || [];
  },

  addAssignmentDetail(state, payload) {
    Vue.set(state.assignmentDetails, payload.id, payload);
  },
  removeAssignment(state, payload) {
    Vue.delete(state.assignments, payload.id);
  },

  setAssignmentFormModel(state, payload) {
    state.assignmentFormModel = payload;
  },

  setCurrentAssignmentId(state, payload) {
    state.currentAssignmentId = payload;
  },
  updateAssignmentCourseLoadedStatus(state, payload) {
    Vue.set(
      state.areAssignmentsLoadedForCourse,
      payload.courseId,
      payload.status
    );
  },
  updateAssignmentCourseLoadingStatus(state, payload) {
    Vue.set(
      state.areAssignmentsLoadingForCourse,
      payload.courseId,
      payload.status
    );
  },

  updateAssignmentSectionLoadedStatus(state, payload) {
    Vue.set(
      state.areAssignmentsLoadedForSection,
      payload.sectionId,
      payload.status
    );
  },
  updateAssignmentSectionLoadingStatus(state, payload) {
    Vue.set(
      state.areAssignmentsLoadingForSection,
      payload.sectionId,
      payload.status
    );
  },
  updateOrganizationLoadedStatus(state, payload) {
    Vue.set(
      state.areSharedAssignmentsLoadedForOrganization,
      payload.orgId,
      payload.status
    );
  },

  updateUserSharedAssignmentLoadStatus(state, payload) {
    Vue.set(state, "areSharedAssignmentsLoadedForUser", payload.status);
  },
  updateAssignmentKeyLoadStatus(state, payload) {
    Vue.set(
      state.areKeysLoadedForAssignment,
      payload.assignmentId,
      payload.status
    );
  },
};

export const actions = {
  async loadSectionAssignments(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    const sectionId = args.sectionId;
    if (!sectionId) throw new Error("No sectionId provided");

    // If we have already loaded the assignments for the section, don't load them again
    if (
      !args.forced &&
      (state.areAssignmentsLoadingForSection[sectionId] ||
        state.areAssignmentsLoadedForSection[sectionId])
    )
      return;
    commit("updateAssignmentSectionLoadingStatus", {
      sectionId,
      status: true,
    });

    try {
      await AssignmentService.getAssignmentsBySectionId(
        sectionId,
        async (assignment) => {
          commit("addAssignment", assignment);
        }
      );
    } catch (err) {
      console.error(err);
    } finally {
      commit("updateAssignmentSectionLoadedStatus", {
        sectionId,
        status: true,
      });
      commit("updateAssignmentSectionLoadingStatus", {
        sectionId,
        status: false,
      });
    }
  },
  async loadCourseAssignments(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    const courseId = args.courseId;
    if (!courseId) throw new Error("No courseId provided");

    console.log("LOADING COURSE ASSIGNMENTS");
    // If we have already loaded the assignments for the course, don't load them again
    if (
      !args.forced &&
      (state.areAssignmentsLoadedForCourse[args.courseId] ||
        state.areAssignmentsLoadingForCourse[args.courseId])
    )
      return;
    commit("updateAssignmentCourseLoadingStatus", {
      courseId: args.courseId,
      status: true,
    });

    try {
      await AssignmentService.getAssignmentsByCourseId(
        args.courseId,
        async (assignment) => {
          commit("addAssignment", assignment);
        }
      );
    } catch (err) {
      console.error(err);
    }

    commit("updateAssignmentCourseLoadedStatus", {
      courseId: args.courseId,
      status: true,
    });
    commit("updateAssignmentCourseLoadingStatus", {
      courseId: args.courseId,
      status: false,
    });
  },

  async loadSharedAssignmentsForOrganization(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    const orgId = args.orgId;
    if (!orgId) throw new Error("No orgId provided");

    // If we have already loaded the assignments for the course, don't load them again
    if (
      !args.forced &&
      (state.areSharedAssignmentsLoadedForOrganization[orgId] ||
        state.areSharedAssignmentsLoadedForUser)
    )
      return;
    commit("updateOrganizationLoadedStatus", {
      orgId,
      status: true,
    });

    try {
      await AssignmentService.getSharedAssignmentsForOrg(
        orgId,
        rootState.auth.user.uid,
        async (assignment) => {
          commit("addAssignment", assignment);
        }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async loadAllSharedAssignments(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    // If we have already loaded the shared assignments for the user, don't load them again
    if (!args.forced && state.areSharedAssignmentsLoadedForUser) return;
    commit("updateUserSharedAssignmentLoadStatus", {
      status: true,
    });

    try {
      await AssignmentService.getAllSharedAssignments(
        rootState.auth.user.uid,
        async (assignment) => {
          commit("addAssignment", assignment);
        }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async loadAssignment(
    { state, dispatch, commit, rootState },
    args = { forced: false }
  ) {
    const assignmentId = args.assignmentId;
    if (!assignmentId) throw new Error("No assignmentId provided");

    // If we have the assignment already, don't load it again
    if (!args.forced && state.assignments[assignmentId]) return;
    const assignment = await AssignmentService.getAssignment(assignmentId);
    commit("addAssignment", assignment);
  },

  async loadAssignmentDetail(
    { state, dispatch, commit, rootState },
    args = { forced: false }
  ) {
    const assignmentDetailId = args.assignmentDetailId;
    if (!assignmentDetailId) throw new Error("No assignmentDetailId provided");

    // If we have the assignment already, don't load it again
    if (!args.forced && state.assignmentDetails[assignmentDetailId]) return;
    const assignmentDetail = await AssignmentService.getAssignmentDetail(
      assignmentDetailId
    );
    commit("addAssignmentDetail", assignmentDetail);
  },

  async loadAllAssignmentKeys(
    { state, dispatch, commit, rootState },
    args = { forced: false }
  ) {
    const assignmentId = args.assignmentId;
    if (!assignmentId) throw new Error("No assignmentId provided");
    // If we have the assignment already, don't load it again
    if (!args.forced && state.areKeysLoadedForAssignment[assignmentId]) return;
    commit("updateAssignmentKeyLoadStatus", {
      assignmentId,
      status: true,
    });
    const allKeys = await AssignmentService.getAllKeysForAssignment(
      assignmentId
    );
    Object.keys(allKeys).forEach((uid) => {
      commit("addAssignmentKey", { uid, key: allKeys[uid], assignmentId });
    });
  },
  async loadAssignmentKey(
    { state, dispatch, commit, rootState },
    args = { forced: false }
  ) {
    const assignmentId = args.assignmentId;
    const uid = args.uid;

    if (!assignmentId) throw new Error("No assignmentId provided");
    if (!uid) throw new Error("No uid provided");
    // If we have the assignment already, don't load it again
    if (!args.forced && state.assignmentKeys[assignmentId]?.[uid]) return;

    const key = await AssignmentService.getAssignmentKey(assignmentId, uid);

    commit("addAssignmentKey", { uid, key, assignmentId });
  },
};
