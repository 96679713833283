function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "sessions",
  component: lazyLoad("SessionsRoot"),
  props: true,
  children: [
    {
      path: "",
      component: lazyLoad("SessionsView"),
      props: true,
    },
    {
      path: ":sessionId",
      component: lazyLoad("SessionRoot"),
      props: true,
      children: [
        {
          path: "",
          component: lazyLoad("SessionView"),
          props: true,
        },
        {
          path: "bibliography",
          name: "SessionBibliographyView",
          component: lazyLoad("SessionBibliographyView"),
          props: true,
        },
        {
          path: "bibliography-legacy",
          name: "OldSessionBibliographyView",
          component: lazyLoad("OldSessionBibliographyView"),
          props: true,
        },
      ],
    },
  ],
};
