import { organizationPortalBreadcrumbs } from "@/views/organizations/routeBreadcrumbs";

// Use the portal breadcrumbs if they are not the last item in the breadcrumbs array
export const coursePortalBreadcrumbs = [
  ...organizationPortalBreadcrumbs,
  {
    dynamicLabel: "course",
    toName: "CoursePortal",
  },
];

export const courseBreadcrumbs = [
  ...organizationPortalBreadcrumbs,
  {
    dynamicLabel: "course",
  },
];
