/**
 * A class to handle messages directly from extensions.  This is not the
 * preferred way to communicate with extensions from `learnics-app`, but it may
 * prove necessary in some cases.
 *
 * Without it, the only way extensions can really get a message to `learnics-app`
 * is by opening a special URL or by responding to a message `learnics-app` sends.
 *
 * This allows for spontaneous messages pushed from an extension directly to an
 * instance of `learnics-app` in a tab.
 *
 * To listen for messages from extensions, use the `addMessageHandler` method,
 * and be sure to call the returned function when you are done listening to unregister
 * the listener.
 */
export class ExtensionMessageHandler {
  constructor() {
    this.messageHandlers = new Map();
  }

  /**
   * Add a message handler for a particular key.  The key is the message type.
   *
   * To track messages from all extensions, give no extensionIds.  To track messages
   * from a particular extension, give an array with a single extensionId, and so on.
   *
   * @param messageType
   * @param listener
   * @return {(function(): void)|*}
   */
  addMessageHandler(messageType, listener) {
    if (!this.messageHandlers.has(messageType)) {
      this.messageHandlers.set(messageType, new Set());
    }
    this.messageHandlers.get(messageType).add(listener);

    return () => {
      this.messageHandlers.get(messageType).delete(listener);
    };
  }

  /**
   * Process a message from an extension, and call all the listeners that have been
   * registered for that message.
   *
   * @param data the message data
   * @param sender the sender of the message
   * @return {Promise<void>}
   */
  async processMessage(data, sender) {
    if (!this.messageHandlers.has(data.action)) {
      console.log("No listeners for message", data.action);
      return;
    }
    for (const listener of this.messageHandlers.get(data.action)) {
      await listener(JSON.parse(JSON.stringify(data)), sender);
    }
  }
}
