import { courseBreadcrumbs } from "./routeBreadcrumbs";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "courses/:courseId",
  component: lazyLoad("CourseRoot"),
  meta: {
    breadcrumbs: courseBreadcrumbs,
  },
  props: true,
  children: [
    {
      name: "CoursePortal",
      path: "",
      component: lazyLoad("CoursePortal"),
      props: true,
    },
    {
      path: "settings",
      name: "CourseSettingsView",
      component: lazyLoad("CourseSettingsView"),
      props: true,
    },
    {
      path: "roster",
      name: "CourseRosterView",
      component: lazyLoad("CourseRosterView"),
      props: true,
    },
    {
      path: "sections",
      component: lazyLoad("CourseSectionsRoot"),
      props: true,
      redirect: "./",
      children: [
        {
          path: "",
          name: "CourseSectionsView",
          component: lazyLoad("CourseSectionsView"),
          props: true,
        },
      ],
    },
    {
      path: "assignments",
      name: "CourseAssignmentsRoot",
      component: lazyLoad("CourseAssignmentsRoot"),
      props: true,
      redirect: "./",
      children: [
        {
          path: "",
          name: "CourseAssignmentsView",
          component: lazyLoad("CourseAssignmentsView"),
          props: true,
        },
      ],
    },
  ],
};
