<template>
  <v-app
    v-if="loading || reloadingExtensions"
    id="main"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <v-main
      style="min-height: 100vh"
      class="d-flex justify-center align-center"
    >
      <div>
        <div class="text-center py-4">
          <div>
            <img
              alt="learnics logo"
              src="/learnics-spinner.svg"
              class="mb-6"
              style="max-width: 74px"
            />
          </div>
          <img
            alt="learnics"
            src="/learnics-title.png"
            style="max-width: 270px"
            class="mb-6"
          />
        </div>
        <div></div>
        <ExpandablePanel
          :value="troubleLoading && !reloadingExtensions"
          class="body-2 text-center"
        >
          <div class="px-4">
            <v-card style="max-width: 675px; margin: auto">
              <v-card-title
                class="headline teacherPrimary lightest--text justify-center"
                primary-title
              >
                <div class="d-flex align-start justify-center">
                  <div class="mr-3">
                    <v-icon color="lightest">mdi-wifi-alert</v-icon>
                  </div>
                  <div class="text-left">
                    <div style="word-break: normal">
                      Trying to Connect to Your
                      {{ tryingToConnectToExtensionTitle }} Extension...
                    </div>
                  </div>
                </div>
              </v-card-title>

              <v-card-text class="py-4 px-8">
                <div class="body-1" style="font-size: 18px !important">
                  <div class="mb-2">
                    It appears you've installed an extension, but we are having
                    trouble connecting to it.
                  </div>

                  <div
                    v-if="
                      user && installations && sortedInstallationIds.length > 0
                    "
                    class="mt-4"
                  >
                    <div>
                      You can manually reload your extension to try to restore
                      functionality, or we can try to help you troubleshoot the
                      issue by attempting to automatically reload your
                      extension. It may take a moment to reach your extension.
                    </div>
                  </div>
                  <div v-else>
                    <div class="pb-2">
                      Please try restarting or reinstalling your
                      {{ tryingToConnectToExtensionTitle }} extension to restore
                      functionality.
                    </div>
                    <div class="pb-2">
                      Contact us at
                      <a href="mailto:support@learnics.com"
                        >support@learnics.com</a
                      >
                      if you continue to have trouble after reloading your
                      extension. We apologize for the inconvenience.
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <div class="pa-4">
                <div v-if="sortedInstallationIds?.length > 0" class="mb-4">
                  <v-btn
                    block
                    x-large
                    :disabled="reloadingExtensions"
                    @click="tryToReloadExtensions()"
                    color="teacherPrimary lightest--text"
                    class="mt-4"
                    >Attempt to Reload My Extension</v-btn
                  >
                </div>
                <div>
                  <v-btn
                    :color="
                      sortedInstallationIds?.length === 0
                        ? 'teacherPrimary lightest--text'
                        : 'lightest teacherPrimary--text'
                    "
                    block
                    x-large
                    @click="continueWithoutExtension"
                  >
                    Continue without extension connectivity
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </ExpandablePanel>
        <ExpandablePanel :value="!troubleLoading && !reloadingExtensions">
          <div class="body-2 text-center">{{ loadingText }}</div>
        </ExpandablePanel>
        <ExpandablePanel
          :value="!!reloadingExtensions"
          class="body-2 text-center"
        >
          <div class="px-4">
            <v-card style="max-width: 675px; margin: auto">
              <v-card-title
                class="headline teacherPrimary lightest--text justify-center"
                primary-title
              >
                <div class="d-flex align-start justify-center">
                  <div class="mr-3">
                    <v-icon color="lightest">mdi-wifi-alert</v-icon>
                  </div>
                  <div class="text-left">
                    <div style="word-break: normal">
                      Reloading Your {{ tryingToConnectToExtensionTitle }}
                      Extension...
                    </div>
                  </div>
                </div>
              </v-card-title>

              <v-card-text class="py-4 px-8">
                <div class="body-1" style="font-size: 18px !important">
                  <div class="mb-2">
                    We are attempting to reload your
                    {{ tryingToConnectToExtensionTitle }} extension. It may take
                    a moment to reach your extension.
                  </div>
                  <div class="mb-2">
                    If your extension fails to reload and refresh this page
                    after about 30 seconds, you can try manually reloading your
                    extension to restore functionality.
                  </div>
                  <div class="mb-2">
                    To do so, visit the
                    <strong>chrome://extensions</strong> page by typing it into
                    your browser's address bar, find the
                    {{ tryingToConnectToExtensionTitle }} extension, and click
                    the "reload" button next to the extension. You can also
                    reinstall the extension - it's quick and easy and you won't
                    lose any of your notes or logging sessions.
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </ExpandablePanel>
      </div>
    </v-main>
  </v-app>

  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import {
  getExtensionControllerById,
  oneClickController,
  studentLinkController,
  teacherLinkController,
} from "@/services/extensionControllers";
import { myTabId } from "@/services/myTabId";
import { mapGetters } from "vuex";
import ExpandablePanel from "@learnics/services/src/vue/components/ExpandablePanel.vue";
import ExtensionInstallationService from "@learnics/services/src/extensionInstallation";
import CloudMessageService from "@learnics/services/src/cloudMessage";

export default {
  name: "ExtensionLoader",
  components: { ExpandablePanel },
  data() {
    return {
      loading: true,
      troubleLoading: false,
      timeoutId: null,
      installations: null,
      reloadingExtensions: false,
      tryingToConnectToExtensionTitle: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    loadingText() {
      return "Loading Extensions";
    },

    sortedInstallationIds() {
      if (!this.installations) {
        return [];
      }
      return Object.keys(this.installations)
        .filter((id) => {
          return !!this.installations[id].fcmRegistrationId;
        })
        .sort();
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },

  async mounted() {
    const alreadyOptedForLimitedConnectivity =
      localStorage.getItem("limitedExtensionConnectivity") === "true";
    if (alreadyOptedForLimitedConnectivity) {
      // Still try to communicate with the extensions in case they come back
      // online, but don't hang on the loading screen if they don't.
      this.loading = false;
    }
    if (!alreadyOptedForLimitedConnectivity) {
      this.timeoutId = setTimeout(async () => {
        try {
          await getExtensionControllerById(this.extensionId).sendMessage({
            action: "updatePopupSize",
            width: 720,
            height: 720,
          });
        } catch (error) {
          console.log(error);
        }
        if (this.user && !this.installations) {
          this.installations =
            await ExtensionInstallationService.getInstallationsByUserId(
              this.user.uid
            );

          console.log("installations = ", this.installations);
        } else {
          console.log("no user or installations already loaded");
        }
        this.troubleLoading = true;
      }, 5000);
    }

    const extensions = [
      oneClickController,
      teacherLinkController,
      studentLinkController,
    ];
    const titles = ["One Click", "Classroom Pro", "Learnics"];
    for (let i = 0; i < extensions.length; i++) {
      const extension = extensions[i];
      this.tryingToConnectToExtensionTitle = titles[i];
      try {
        await extension.initialize();
      } catch (err) {
        console.error(
          "There was an unexpected error while attempting to connect to the extension ",
          extension.extensionId,
          err
        );
      }
    }
    await myTabId.load();
    // If we've gotten here it means there are no connectivity issues (e.g.
    // either everything failed or succeeded and we can remove the
    // `limitedExtensionConnectivity` flag (if it exists)
    localStorage.removeItem("limitedExtensionConnectivity");
    clearTimeout(this.timeoutId);
    this.loading = false;
  },

  methods: {
    continueWithoutExtension() {
      void myTabId.load();
      localStorage.setItem("limitedExtensionConnectivity", "true");
      this.loading = false;
    },
    async tryToReloadExtensions() {
      this.reloadingExtensions = true;
      await CloudMessageService.sendCloudMessageToExtensions(
        [...this.sortedInstallationIds],
        {
          messages: JSON.stringify([
            {
              actions: [
                {
                  action: "reloadExtension",
                  postReloadMessages: [
                    {
                      action: "reloadLearnicsTabs",
                    },
                  ],
                },
              ],
            },
          ]),
        }
      );
    },
  },
};
</script>
