import {
  organizationBreadcrumbs,
  organizationsBreadcrumbs,
} from "@/views/organizations/routeBreadcrumbs";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "organizations",
  name: "OrganizationsRoot",
  component: lazyLoad("OrganizationsRoot"),
  redirect: "./",
  meta: {
    breadcrumbs: organizationsBreadcrumbs,
  },
  children: [
    {
      path: "",
      name: "OrganizationsView",
      component: lazyLoad("OrganizationsView"),
    },
    {
      path: ":orgId",
      component: lazyLoad("OrganizationRoot"),
      props: true,
      meta: {
        breadcrumbs: organizationBreadcrumbs,
      },
      children: [
        {
          path: "",
          name: "OrganizationPortal",
          redirect: "./courses",
          props: true,
        },
        {
          path: "roster",
          name: "OrganizationRosterView",
          component: lazyLoad("OrganizationRosterView"),
          props: true,
        },
        {
          path: "settings",
          name: "OrganizationSettingsView",
          component: lazyLoad("OrganizationSettingsView"),
          props: true,
        },
        {
          path: "courses",
          component: lazyLoad("CoursesRoot"),
          props: true,
          redirect: "./",
          children: [
            {
              path: "",
              name: "CoursesView",
              component: lazyLoad("CoursesView"),
              props: true,
              children: [
                {
                  path: ":courseId",
                  name: "SelectedCourse",
                  props: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
