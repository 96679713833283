import GapiOauth2Service from "@learnics/services/src/gapiOauth2";

export async function validateGapiTokenForScopes(
  scopes,
  currentUserId = null,
  gapiToken = null
) {
  const result = {
    shouldRefresh: false,
  };
  const missingScopes = [];
  try {
    if (currentUserId) {
      if (!gapiToken) {
        gapiToken = await GapiOauth2Service.getGapiOauth2Token(currentUserId);
      }
      console.log("gapiToken: ", gapiToken);
      if (gapiToken) {
        // User is logged in and has a gapi token.. check the token to see if we even need to request more scopes
        const existingScopes = new Set(gapiToken.scope.split(" "));
        console.log("existingScopes: ", existingScopes);
        for (let scope of scopes) {
          if (Array.isArray(scope)) {
            let matchesAnySubScope = false;
            for (let subScope of scope) {
              matchesAnySubScope ||= existingScopes.has(subScope);
            }
            if (!matchesAnySubScope) {
              console.log("Missing scope: ", scope);
              missingScopes.push(scope);
            }
          } else if (!existingScopes.has(scope)) {
            console.log("Missing scope: ", scope);
            missingScopes.push(scope);
          }
        }
        if (missingScopes.length === 0) {
          console.log("Scopes already authorized");
          // All the scopes have already been authorized.  We don't need to do the auth flow again!

          // if the token expires before 10 seconds from now...
          console.log("gapiToken.expiry_date: ", gapiToken.expiry_date);
          console.log("Date.now(): ", Date.now());
          if (gapiToken.expiry_date < Date.now() + 10000) {
            console.log("Token apparently expired or about to be, refreshing");
            // Token needs refreshed
            result.shouldRefresh = true;
          }
        }
      } else {
        // User is logged in but has no gapi token.  Request the minimal amount of scopes required, as they will be
        // progressively requested as needed.
        missingScopes.push(...scopes);
      }
    } else {
      missingScopes.push(...scopes);
    }
  } catch (err) {
    console.log("Error validating gapi token for scopes", err);
    missingScopes.push(...scopes);
  }
  result.missingScopes = missingScopes.map((scope) => {
    if (Array.isArray(scope)) {
      return scope[0]; // Return the first scope in the array, presumably the least privileged
    } else {
      return scope;
    }
  });

  return result;
}
