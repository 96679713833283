import { coursePortalBreadcrumbs } from "@/views/courses/routeBreadcrumbs";

// Use the portal breadcrumbs if they are not the last item in the breadcrumbs array
export const sectionPortalBreadcrumbs = [
  ...coursePortalBreadcrumbs,
  {
    dynamicLabel: "section",
    toName: "SectionPortal",
    onlyShowIf: (org, course, section, assignment) => {
      return course?.hasSections;
    },
  },
];

export const sectionBreadcrumbs = [
  ...coursePortalBreadcrumbs,
  {
    dynamicLabel: "section",
  },
];
