function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default {
  path: "start",
  name: "StartAssignmentRoot",
  component: lazyLoad("StartAssignmentRoot"),
  redirect: "./",
  children: [
    {
      path: "",
      name: "StartAssignmentView",
      component: lazyLoad("StartAssignmentView"),
    },
    {
      path: "auto",
      name: "AutoStartAssignmentView",
      component: lazyLoad("StartAssignmentView"),
    },
    {
      path: "scheduled-assignment",
      name: "ScheduledAssignmentView",
      component: lazyLoad("ScheduledAssignmentView"),
    },
    {
      path: "missing-learnics-extension",
      name: "MissingLearnicsExtensionView",
      component: lazyLoad("MissingLearnicsExtensionView"),
    },
  ],
};
