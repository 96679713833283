import UserService from "@learnics/services/src/user";
import { auth } from "@learnics/services/src/analyticsConfig";

// Prefer this method because it works in the emulator as well as production
export async function getCustomTokenForUser(uid = null, forceRefresh = true) {
  if (!auth.currentUser) {
    throw new Error("No user logged in");
  }
  const baseUrl = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;
  const idToken = await auth.currentUser.getIdToken(forceRefresh);
  return await UserService.getCustomToken(
    idToken,
    uid || auth.currentUser.uid,
    baseUrl
  );
}
