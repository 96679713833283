import adminRoute from "./admin/route";
import organizationsRoute from "@/views/organizations/route";
import assignmentFormRoute from "./assignmentForm/route";
import oldRoutingRedirects from "./oldRoutingRedirects";
import coursesRoute from "@/views/courses/route";
import assignmentsRoute from "@/views/assignments/route";
import sectionsRoute from "@/views/sections/route";
import sessionsRoute from "@/views/sessions/route";
import popupsRoute from "@/views/popups/route";
import noteBoardsRoute from "@/views/noteBoards/route";
import learnicsURoute from "@/views/u/route";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}
export default [
  ...oldRoutingRedirects,
  {
    path: "/login",
    name: "LoginView",
    component: lazyLoad("LoginView"),
  },
  /** @deprecated - Prefer /assignmentForm/lti */
  {
    path: "/lti/assignment-form",
    name: "LtiAssignmentForm",
    component: lazyLoad("assignmentForm/LtiAssignmentFormView"),
  },
  /** @deprecated - Prefer /assignmentForm/classroom */
  {
    path: "/classroom/assignment-form",
    name: "ClassroomAssignmentFormView",
    component: lazyLoad("assignmentForm/ClassroomAssignmentFormView"),
  },
  {
    path: "/studentLogout",
    name: "StudentLogoutView",
    component: lazyLoad("StudentLogoutView"),
  },

  {
    path: "/free-for-2024",
    name: "FreeFor2024View",
    props: true,
    component: lazyLoad("FreeFor2024View"),
  },
  {
    path: "/bucketFile/:fileId",
    name: "BucketFileView",
    props: true,
    component: lazyLoad("BucketFileView"),
  },
  {
    path: "/extensionLogout",
    name: "ExtensionLogoutView",
    component: lazyLoad("ExtensionLogoutView"),
  },
  {
    path: "/localHostAuth",
    name: "LocalHostAuthView",
    component: lazyLoad("LocalHostAuthView"),
  },
  {
    path: "/extensionUninstall",
    name: "ExtensionUninstallView",
    component: lazyLoad("ExtensionUninstallView"),
  },
  {
    path: "/driveFileSelect",
    name: "DriveFileSelectView",
    component: lazyLoad("DriveFileSelectView"),
  },
  {
    path: "/studentLogin",
    name: "StudentLoginView",
    component: lazyLoad("StudentLoginView"),
  },
  {
    path: "/googleLogin",
    name: "GoogleLoginView",
    component: lazyLoad("GoogleLoginView"),
  },
  {
    path: "/customTokenLogin",
    name: "CustomTokenLoginView",
    component: lazyLoad("CustomTokenLoginView"),
  },
  {
    path: "/googleLoginWarning",
    name: "GoogleLoginWarningView",
    component: lazyLoad("GoogleLoginWarningView"),
  },
  {
    path: "/googleOauth2Callback",
    name: "GoogleOauth2CallbackView",
    component: lazyLoad("GoogleOauth2CallbackView"),
  },
  {
    path: "/syncStudentLogin",
    name: "SyncStudentLoginView",
    component: lazyLoad("SyncStudentLoginView"),
  },
  {
    path: "/syncAccounts",
    name: "SyncAccountsView",
    component: lazyLoad("SyncAccountsView"),
  },
  {
    path: "/teacherLogBackIn",
    name: "TeacherLogBackInView",
    component: lazyLoad("TeacherLogBackInView"),
  },
  {
    path: "/respondToAccessRequest/:accessRequestId",
    name: "RespondToAccessRequestView",
    component: lazyLoad("RespondToAccessRequestView"),
    props: true,
  },
  {
    path: "/studentReport/:session_id",
    props: true,
    component: lazyLoad("studentReport/StudentReportRoot"),
    children: [
      {
        path: "",
        name: "StudentReportView",
        props: true,
        component: lazyLoad("studentReport/StudentReportView"),
      },
    ],
  },
  {
    path: "/start",
    name: "IndependentStartView",
    component: lazyLoad("IndependentStartView"),
  },
  noteBoardsRoute,
  learnicsURoute,
  popupsRoute,
  assignmentFormRoute,
  adminRoute,

  {
    path: "/acceptInvitation/:invitationId",
    name: "AcceptInvitationPortal",
    component: lazyLoad("AcceptInvitationPortal"),
    props: true,
  },
  // Note that paths above this / route are checked first.  This / path and its children will not be considered if a
  // path declared above it is matched.  Paths declared below it will all be consumed by the final * route of /.
  {
    path: "/",
    component: lazyLoad("AppRoot"),
    children: [
      { path: "", redirect: "./organizations" },
      {
        path: "logout",
        name: "LogoutView",
        component: lazyLoad("LogoutView"),
      },
      organizationsRoute,
      coursesRoute,
      assignmentsRoute,
      sessionsRoute,
      sectionsRoute,

      {
        path: "enrollStudent/:assignmentId",
        name: "EnrollStudentView",
        component: lazyLoad("EnrollStudentView"),
        props: true,
      },
      {
        path: "assignment/:access_code",
        name: "AssignmentAccessCodePortal",
        component: lazyLoad("AssignmentAccessCodePortal"),
      },
      {
        path: "newTeacher",
        name: "NewTeacherView",
        component: lazyLoad("NewTeacherView"),
        props: true,
      },
      {
        path: "newUser",
        name: "NewUserView",
        component: lazyLoad("NewUserView"),
        props: true,
      },
      {
        path: "newStudent",
        name: "NewStudentView",
        component: lazyLoad("NewStudentView"),
        props: true,
      },
      {
        path: "registerPlatform",
        name: "registerPlatform",
        component: lazyLoad("RegisterPlatformView"),
      },
      {
        name: "PageNotFoundView",
        path: "*",
        component: lazyLoad("PageNotFoundView"),
      },
    ],
  },
];
