import _ from "lodash";

export const state = {
  user: null,
};

export const getters = {
  user: (state) => state.user,
};

export const mutations = {
  setUser: (state, payload) => {
    state.user = payload;
  },
};

export const actions = {
  updateUser({ commit, dispatch }, data) {
    const user = data.user;
    const claims = data.claims;
    const customClaimProperties = [
      "admin",
      "dev",
      "root",
      "payment_tier",
      "test_student",
      "owner_id",
    ];
    if (user) {
      let { displayName, email, photoURL, uid, name } = user;

      displayName ||= name;
      name ||= displayName;

      const cleanedUser = {
        id: uid,
        displayName,
        name,
        email,
        photoURL,
        uid,
        ...customClaimProperties
          .filter((prop) => typeof claims[prop] !== "undefined")
          .reduce((acc, prop) => {
            acc[_.camelCase(prop)] = claims[prop];
            return acc;
          }, {}),
      };
      commit("setUser", cleanedUser);
      commit("addUser", cleanedUser); // Also add to users list
    } else {
      commit("setUser", null);
    }
  },
};
