export default [
  {
    path: "/o",
    name: "OldOrganizationsRoot",
    redirect: "../organizations",
    props: true,
  },
  {
    path: "/o/:orgId",
    name: "OldOrganizationRoot",
    redirect: "./organizations/:orgId",
    props: true,
  },
  {
    path: "/o/:orgId/settings",
    name: "OldOrganizationSettingsRoot",
    redirect: "./organizations/:orgId/settings",
    props: true,
  },
  {
    path: "/o/:orgId/roster",
    name: "OldOrganizationRosterRoot",
    redirect: "./organizations/:orgId/roster",
    props: true,
  },
  {
    path: "/o/:orgId/c",
    name: "OldCoursesRoot",
    redirect: "./organizations/:orgId/courses",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId",
    name: "OldCourseRoot",
    redirect: "./courses/:courseId",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a",
    name: "OldAssignmentsRoot",
    redirect: "./courses/:courseId/assignments",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/settings",
    name: "OldCourseSettingsRoot",
    redirect: "./courses/:courseId/settings",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/roster",
    name: "OldCourseRosterRoot",
    redirect: "./courses/:courseId/roster",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId",
    name: "OldAssignmentRoot",
    redirect: "./assignments/:assignmentId",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student",
    name: "OldStudentRoot",
    redirect: "./assignments/:assignmentId/student",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student/submissions",
    name: "OldStudentSubmissionsRoot",
    redirect: "./assignments/:assignmentId/studentSubmissions",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student/submissions/assignment-data",
    name: "OldStudentAssignmentDataView",
    redirect: "./assignments/:assignmentId/student/submissions/assignment-data",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student/submissions/assignment-details",
    name: "OldStudentAssignmentDetailsView",
    redirect:
      "./assignments/:assignmentId/student/submissions/assignment-details",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student/submissions/bibliography",
    name: "OldStudentBibliographyView",
    redirect: "./assignments/:assignmentId/student/submissions/bibliography",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/student/start",
    name: "OldStudentStartAssignmentRoot",
    redirect: "./assignments/:assignmentId/student/start",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher",
    name: "OldTeacherRoot",
    redirect: "./assignments/:assignmentId/teacher",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions",
    name: "OldTeacherSubmissionsRoot",
    redirect: "./assignments/:assignmentId/teacher/submissions",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/assignment-data",
    name: "OldTeacherAssignmentDataRoot",
    redirect: "./assignments/:assignmentId/teacher/submissions/assignment-data",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/assignment-expectations",
    name: "OldTeacherAssigmentExpectationsRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/assignment-expectations",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/online-activity",
    name: "OldTeacherOnlineActivityRoot",
    redirect: "./assignments/:assignmentId/teacher/submissions/online-activity",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/searches",
    name: "OldTeacherSearchesRoot",
    redirect: "./assignments/:assignmentId/teacher/submissions/searches",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/site-logs",
    name: "OldTeacherSiteLogsRoot",
    redirect: "./assignments/:assignmentId/teacher/submissions/site-logs",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/categories-of-sites",
    name: "OldTeacherCategoriesOfSitesRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/categories-of-sites",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/assignment-details",
    name: "OldTeacherAssignmentDetailsRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/assignment-details",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/student/:studentId",
    name: "OldTeacherIndividualStudentRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/student/:studentId",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/student/:studentId/data",
    name: "OldTeacherIndividualStudentDataRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/student/:studentId/data",
    props: true,
  },
  {
    path: "/o/:orgId/c/:courseId/a/:assignmentId/teacher/submissions/student/:studentId/visualizations",
    name: "OldTeacherIndividualStudentVisualizationsRoot",
    redirect:
      "./assignments/:assignmentId/teacher/submissions/student/:studentId/visualizations",
    props: true,
  },
];
