import CourseService from "@learnics/services/src/course";
import Vue from "vue";
import { getUserRolesOverResource } from "@learnics/models/src/utils/roles";

export const state = {
  courses: {},
  currentCourseId: null,
  areCoursesLoadedForOrg: {},
  areCoursesLoadingForOrg: {},
  areInstructorAssignableCoursesLoaded: false,
};

export const getters = {
  getCourses: (state) => {
    return state.courses;
  },
  getCurrentCourseId: (state) => {
    return state.currentCourseId;
  },

  getAreCoursesLoadedForOrg: (state) => {
    return state.areCoursesLoadedForOrg;
  },

  getAreCoursesLoadingForOrg: (state) => {
    return state.areCoursesLoadingForOrg;
  },

  getAreInstructorAssignableCoursesLoaded: (state) => {
    return state.areInstructorAssignableCoursesLoaded;
  },

  // ******* COMPUTED PROPERTY GETTERS ******* DO NOT TRY TO MUTATE THESE VALUES ********
  getCurrentCourse: (state) => {
    return state.currentCourseId && state.courses[state.currentCourseId];
  },
};

export const mutations = {
  addCourse(state, payload) {
    Vue.set(state.courses, payload.id, payload);
  },
  setCurrentCourseId(state, payload) {
    Vue.set(state, "currentCourseId", payload);
  },
  updateCourseOrgLoadedStatus(state, payload) {
    Vue.set(state.areCoursesLoadedForOrg, payload.orgId, payload.status);
  },
  updateCourseOrgLoadingStatus(state, payload) {
    Vue.set(state.areCoursesLoadingForOrg, payload.orgId, payload.status);
  },
  removeCourse(state, payload) {
    Vue.delete(state.courses, payload.id);
  },

  updateCourseRoles(state, payload) {
    const { id, role, uids } = payload;
    const course = state.courses[id];
    if (!course) throw new Error("Course not found in store.");
    course.roles[role] = uids || [];
  },

  updateInstructorAssignableCoursesLoadedStatus(state, payload) {
    Vue.set(state, "areInstructorAssignableCoursesLoaded", payload);
  },
};

export const actions = {
  async loadCourses({ state, commit, rootState }, args = { forced: false }) {
    const orgId = args.orgId;
    let roles = args.roles;
    if (!roles) {
      roles = getUserRolesOverResource(
        rootState.organizations.organizations[orgId],
        rootState.auth.user.uid
      );
    }
    if (!orgId) throw new Error("No orgId provided");
    if (
      !args.forced &&
      (state.areCoursesLoadedForOrg[orgId] ||
        state.areCoursesLoadingForOrg[orgId])
    )
      return;

    commit("updateCourseOrgLoadingStatus", {
      orgId,
      status: true,
    });
    let uid = rootState.auth.user.uid;
    console.log(
      "Actually fetching courses for user",
      uid,
      "in org",
      orgId,
      "with roles",
      roles
    );

    if (roles.includes("Administrator")) {
      try {
        console.warn("Loading all courses for org", orgId);
        await CourseService.getAllCoursesForOrganization(orgId, (course) => {
          // console.log("ADD COURSE 1", course);
          requestAnimationFrame(() => {
            commit("addCourse", course);
          });
        });
      } catch (err) {
        console.log("Error getting user courses", err);
        throw err;
      }
    } else {
      for (let role of roles) {
        try {
          console.log("Loading courses for user", uid, "with role", role);
          await CourseService.getCourses(orgId, uid, role, (course) => {
            // console.log("ADD COURSE 2", course);
            requestAnimationFrame(() => {
              commit("addCourse", course);
            });
          });
        } catch (err) {
          console.log("Error getting user courses", err);
          throw err;
        }
      }
    }
    commit("updateCourseOrgLoadingStatus", {
      orgId,
      status: false,
    });
    commit("updateCourseOrgLoadedStatus", { orgId, status: true });
  },

  async loadCourse({ state, commit, rootState }, args = { forced: false }) {
    const courseId = args.courseId;
    if (!courseId) throw new Error("No courseId provided");
    if (!args.forced && state.courses[courseId]) {
      return;
    }

    try {
      const courseData = await CourseService.getCourse(courseId);
      if (!courseData) throw new Error("Course #" + courseId + " not found");
      commit("addCourse", courseData);
    } catch (err) {
      console.log("Error loading course:", err);
      throw err;
    }
  },

  async archiveCourse({ state, dispatch, commit, rootState }, args = {}) {
    const courseId = args.courseId;
    if (!courseId) throw new Error("No courseId provided");
    const course = state.courses[courseId];
    if (!course) throw new Error("Course not found");
    await CourseService.archiveCourse(courseId, true, true);

    commit("removeCourse", { id: courseId });
  },

  async upgradeCourse({ state, dispatch, commit, rootState }, args = {}) {
    const courseId = args.courseId;
    if (!courseId) throw new Error("No courseId provided");
    const newSectionName = args.newSectionName;
    if (!newSectionName) throw new Error("No newSectionName provided");
    const course = state.courses[courseId];
    if (!course) throw new Error("Course not found");
    if (course.hasSections) {
      console.warn(
        "Course " + courseId + " already upgraded, skipping upgrade"
      );
      return;
    } else {
      const { course, section } =
        await CourseService.upgradeCourseToUseSections(
          courseId,
          newSectionName
        );
      commit("addCourse", course);
      commit("addSection", section);
    }
  },
  async loadAssignableCoursesForInstructor(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    let callback = args.callback || (async () => {});
    if (!args.forced && state.areInstructorAssignableCoursesLoaded) return;

    let uid = rootState.auth.user.uid;
    try {
      await CourseService.getAllAssignableCoursesForInstructor(
        uid,
        (course) => {
          commit("addCourse", course);
          callback(course);
          // commit("addSection", section)
        }
      );
    } catch (err) {
      console.log("Error getting user sections", err);
      throw err;
    }

    commit("updateInstructorAssignableCoursesLoadedStatus", true);
  },
};
