function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "/u",
  name: "LearnicsURoot",
  component: lazyLoad("LearnicsURoot"),

  props: true,
  children: [
    {
      path: "terms-of-service",
      name: "LearnicsUTermsOfServiceView",
      component: lazyLoad("LearnicsUTermsOfServiceView"),
      props: true,
    },

    {
      path: "tour",
      name: "LearnicsUTourView",
      component: lazyLoad("LearnicsUTourView"),
    },
    {
      path: "payment-portal",
      name: "LearnicsUPaymentPortalView",
      component: lazyLoad("LearnicsUPaymentPortalView"),
    },
  ],
};
