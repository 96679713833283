import Vue from "vue";

export const state = {
  currentStudentId: null,
};

export const getters = {
  getCurrentStudentId: (state) => state.currentStudentId,
  getCurrentStudent(state, getters, rootState) {
    return rootState.users.users[state.currentStudentId] || null;
  },
};

export const mutations = {
  setCurrentStudentId(state, payload) {
    Vue.set(state, "currentStudentId", payload);
  },
};

export const actions = {};
