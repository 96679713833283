var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "learnics-app"
  }, [_c('ExtensionLoader', [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }