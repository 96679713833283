import { store } from "@/store";
import { compareVersions } from "compare-versions";
import { ExtensionMessageHandler } from "./ExtensionMessageHandler";

export class ExtensionController {
  constructor(extensionId) {
    this.port = null;
    this.extensionId = extensionId;
    this.manifest = null;
    this.authState = null;
    this.storageChangedListeners = new Set();
    this.messageHandler = new ExtensionMessageHandler();
    this.connected = false;
  }

  addStorageChangedListener(listener) {
    this.storageChangedListeners.add(listener);
    return () => {
      this.storageChangedListeners.delete(listener);
    };
  }

  sendMessageSync(data, resolve, reject) {
    if (typeof chrome === "undefined" || !chrome?.runtime?.sendMessage) {
      throw new Error("Missing chrome.runtime.sendMessage.");
    }
    chrome.runtime.sendMessage(this.extensionId, data, (res) => {
      const lastError = chrome.runtime.lastError;
      if (res) {
        return resolve(res);
      } else {
        if (lastError) {
          reject(lastError.message);
        } else {
          reject(new Error("Unknown error."));
        }
      }
    });
  }
  async sendMessage(data) {
    return new Promise((resolve, reject) => {
      this.sendMessageSync(data, resolve, reject);
    });
  }

  async initialize() {
    try {
      const resp = await this.sendMessage({
        action: "getManifest",
      });
      if (resp.success) {
        this.manifest = resp.data?.manifest || resp.data; // Backward compatible.  Should be just coming in as `resp.data`.
        console.log(
          "Learnics Chrome Extension is installed",
          this.manifest.version
        );

        const storageInfo = await this.sendMessage({
          action: "getStorageInfo",
        });
        if (storageInfo.success) {
          const { sync, local } = storageInfo.data;

          const localKeys = Object.keys(local);
          this.authState = local["authState"];
          for (let key of localKeys) {
            for (let listener of this.storageChangedListeners) {
              listener(this.extensionId, {
                key,
                oldValue: null,
                newValue: local[key],
              });
            }
          }
        }
        this.connectPort();

        store.commit("setExtensionManifest", {
          extensionId: this.extensionId,
          manifest: this.manifest,
        });
        this.messageHandler.addMessageHandler(
          "serviceWorkerInitialized",
          async (data) => {
            if (!this.connected) {
              this.connectPort();
            }
          }
        );
        console.log("Extension " + this.extensionId + " initialized.");
      } else if (resp.message !== "Missing chrome.runtime.sendMessage.") {
        console.log("Extension " + this.extensionId + " not detected.");
      }
    } catch (e) {
      console.log("Error initializing extension: ", e);
    }
  }

  compareVersion(version) {
    if (!this.manifest) {
      throw new Error("Extension not initialized.");
    }
    return compareVersions(this.manifest.version, version);
  }

  isAtLeast(version) {
    return this.manifest && this.compareVersion(version) >= 0;
  }

  isLessThan(version) {
    return this.manifest && this.compareVersion(version) < 0;
  }

  async updateAuthState() {
    if (this.isLessThan("3.39.0")) {
      throw new Error(
        "Extension version is too old to support authState updates."
      );
    }
    const result = await this.sendMessage({
      action: "getEntityValue",
      key: "authState",
    });
    if (result.success) {
      this.authState = result.data;
    }
  }

  connectPort() {
    console.log(`Attempting to connect to extension ${this.extensionId}...`);
    if (typeof chrome === "undefined" || !chrome?.runtime?.connect) {
      console.log("Chrome not found during connectToExtensions");
      return;
    }
    const port = chrome.runtime.connect(this.extensionId, {
      name: "learnics-app",
    });
    this.connected = true;
    port.onMessage.addListener((message) => {
      if (message.action === "storageChanged") {
        if (message.changes.authState) {
          this.authState = message.changes.authState.newValue;
        }
        for (let [key, { oldValue, newValue }] of Object.entries(
          message.changes
        )) {
          this.storageChangedListeners.forEach((listener) => {
            listener(this.extensionId, { key, oldValue, newValue });
          });
        }
      }
    });
    port.onDisconnect.addListener(() => {
      this.connected = false;
      if (
        chrome.runtime.lastError?.message ===
        "Could not establish connection. Receiving end does not exist."
      ) {
        console.log(`No student extension detected.`);
      } else {
        if (chrome.runtime.lastError) {
          console.log(
            `Error from student extension on disconnect:`,
            chrome.runtime.lastError.message
          );
        }
      }
      this.port = null;
    });
    this.port = port;
  }
}
