import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  options: {
    customProperties: true,
  },
  theme: {
    themes: {
      // redesigned color scheme
      light: {
        background: "#ECF0F1", // Learnics light grey
        primary: "#2C3E50", // learnics dark blue
        accent: "#6a8bad", // Learnics dark blue lighten-2
        secondary: "#2980B9", // Learnics medium blue
        error: "#E74C3C", // Learnics red
        lighter: "#cdd8e4", // Learnics dark blue lighten-4
        darkBlueLighten3: "#9bb2c8",
        lightest: "#fbfcfc", // Learnics light gray lighten-4
        learnicsGreen: "#289976", // Learnics green
        lightGrayLighten3: "#f7f9f9", // Learnics light grey lighten 3
        lightBlue: "#3498DB", // Learnics light blue
        darkBlueLighten1: "#476582",
        medBlueLighten4: "#d1e6f5",
        teacherPrimary: "#476582", // darkBlueLighten1
        studentPrimary: "#289976", // learnicsGreen
        teacherBackground: "#cdd8e4", // lighter
        studentBackground: "#ECF0F1", // background
        studentSecondary: "#2980B9", // Learnics medium blue
        teacherSecondary: "#289976", // learnics green
        redLighten1: "#ec7063",
      },
    },
  },
});
