import { auth } from "@learnics/services/src/analyticsConfig";
import { signInWithCustomToken } from "firebase/auth";
import { getAllExtensionControllers } from "@/services/extensionControllers";
import { getCustomTokenForUser } from "@/utils/getCustomTokenForUser";

let synchronizing = false;

export async function synchronizeUserAccounts(user, forceThisUser = false) {
  const extensionControllers = getAllExtensionControllers().filter((c) =>
    c.isAtLeast("3.39.0")
  );
  let customToken = null;
  if (!extensionControllers.length || synchronizing) {
    if (synchronizing) {
      console.warn(
        "Already synchronizing user accounts.  Ignoring this attempt to synchronize user accounts."
      );
    } else {
      console.warn(
        "No extension controllers found, or all extension controllers are older than 3.39.0.  Ignoring this attempt to synchronize user accounts."
      );
    }
    return { user, customToken };
  }
  synchronizing = true;
  try {
    const extensionUserIds = extensionControllers.reduce((acc, c) => {
      acc[c.extensionId] = c.authState?.loggedIn && c.authState?.user?.uid;
      return acc;
    }, {});

    if (!user) {
      // If we don't have a user, then we need to see if any of the extensions
      // have a user.  If so, then we will log in as the first one we find
      // (checking the student extension first, then the teacher extension, then
      // the one-click extension).

      for (let extensionController of extensionControllers) {
        const signedInUserId =
          extensionUserIds[extensionController.extensionId];
        if (!signedInUserId) continue;

        try {
          if (signedInUserId) {
            const result = await extensionController.sendMessage({
              action: "getCustomToken",
            });

            console.log(
              "Got a response back from the extension's getCustomToken:",
              result
            );
            if (!result.success) {
              continue;
            }
            customToken = result.data || result.customToken;
            try {
              console.log("Signing in with customToken ", customToken);
              const customTokenLoginResult = await signInWithCustomToken(
                auth,
                customToken
              );
              user = customTokenLoginResult.user;
              break;
            } catch (e) {
              console.log("Error sending login message to extensions: ", e);
            }
          }
        } catch (e) {
          console.log("Error sending login message to extensions: ", e);
        }
      }
    } else if (!forceThisUser && user) {
      // If we are logged in, but we aren't forcing this user on everything else,
      // then we need to see if we're mismatched or synchronized.  If synchronized,
      // then we don't need to do anything.  If mismatched, then we need to log
      // out of this user and log in as the other user.
      for (let extensionController of extensionControllers) {
        try {
          const signedInUserId =
            extensionUserIds[extensionController.extensionId] || null;
          if (signedInUserId && signedInUserId === user.uid) {
            // First extension seen is synced with this extension.  No need to do anything more in this loop.
            break;
          }
          if (!signedInUserId) {
            continue;
          }
          console.log("Attempting to log in as " + signedInUserId + ".");

          const tokenResult = await extensionController.sendMessage({
            action: "getCustomToken",
          });
          customToken = tokenResult.data || tokenResult.customToken;
          try {
            console.log("Signing in with customToken ", customToken);
            const customTokenLoginResult = await signInWithCustomToken(
              auth,
              customToken
            );
            user = customTokenLoginResult.user;
            console.log("Found a user and logged in with custom token.");
            break;
          } catch (e) {
            console.log("Error sending login message to extensions: ", e);
          }
        } catch (e) {
          console.log("Error sending login message to extensions: ", e);
        }
      }
    }
    if (user) {
      // If we have a user now, then we can assume it's the right one to be logged
      // into, and that anything else not logged into it is wrong, so we need to
      // make sure all the extensions are logged in as this user, too.
      customToken = null;
      for (let extensionController of extensionControllers) {
        try {
          const signedInUserId =
            extensionUserIds[extensionController.extensionId] || null;
          if (signedInUserId === user.uid) {
            continue;
          }
          console.log(
            "Extension " +
              extensionController.extensionId +
              " is logged in as " +
              signedInUserId +
              ", but should be logged in as " +
              user.uid +
              ".  Logging them in now."
          );

          if (!customToken) {
            customToken = await getCustomTokenForUser(user.uid);
            console.log("Sending custom token to extension: ", customToken);
          }

          await extensionController.sendMessage({
            action: "appTokenLogin",
            customToken,
            uid: user.uid,
          });
          break; // Break after the first successful message.
        } catch (e) {
          console.log("Error sending login message to extensions: ", e);
        }
      }
    }
  } catch (e) {
    console.log("Error synchronizing user accounts: ", e);
  }
  synchronizing = false;
  return { user, customToken };
}
