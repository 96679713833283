import { store } from "@/store";
import { eventComparator } from "@learnics/models/src/Organization/Course/Assignment/Event/utils/eventComparator";
import { loggerSessionFromJson } from "@learnics/models/src/Organization/Course/Assignment/Submission/loggerSessionFromJson";
import _ from "lodash";
import { transitionCalculators } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/utils/transitionCalculators";
import { eventFromJson } from "@learnics/models/src/Organization/Course/Assignment/Event/utils/eventFromJson";
import { redact } from "@learnics/models/src/utils/redact";
import { Assignment } from "@learnics/models/src/Organization/Course/Assignment/Assignment";
import { AssignmentDetail } from "@learnics/models/src/Organization/Course/Assignment/AssignmentDetail";
import { UserSiteData } from "@learnics/models/src/Organization/Course/Assignment/Submission/UserSiteData";

let eventCache = {};
let secretsCache = {};

const clearEventCache = _.debounce(() => {
  const sessionIds = Object.keys(eventCache);
  let newEventCache = {};
  for (const sessionId of sessionIds) {
    const batchIds = Object.keys(eventCache[sessionId]);
    const allEvents = [];
    for (const batchId of batchIds) {
      allEvents.push(...eventCache[sessionId][batchId]);
    }
    allEvents.sort(eventComparator);
    const result = transitionCalcs(sessionId, allEvents);
    if (!result) {
      newEventCache[sessionId] = eventCache[sessionId];
    }
  }
  eventCache = newEventCache;
  if (Object.keys(eventCache).length > 0) {
    setTimeout(clearEventCache, 5000); // Check back in 5 seconds, ultimately looping until all events are processed
    clearEventCache();
  }
}, 1000);

/**
 * Attempt to transition the calculators for a given session based on events
 * emitted from the student's extension.
 **/

export function transitionCalcs(sessionId, events) {
  const session = store.getters.getLoggerSessions[sessionId];

  const runningCalculators = store.getters.getRunningCalculators;
  const assignments = store.getters.getAssignments;
  const assignmentDetails = store.getters.getAssignmentDetails;
  const assignment = assignments[session?.assignmentId];
  const assignmentDetail = assignmentDetails[assignment?.assignmentDetailId];
  const keysDeleted = store.getters.getKeysDeleted[sessionId];
  let secrets = store.getters.getSecrets[sessionId] || {};
  if (secretsCache[sessionId]) {
    secrets = { ...secrets, ...secretsCache[sessionId] };
  }

  if (!sessionId || !secrets || !keysDeleted) {
    return false;
  }
  let commonCalculators = {};
  if (session?.assignmentId) {
    commonCalculators =
      store.getters.getCommonRunningCalculators[session.assignmentId] || {};
  }
  const stateData = {
    assignment,
    assignmentDetail,
    keysDeleted,
    keysBlacklisted: new Set(),
    secrets,
    sessionId,
    timeRange: {
      start: 0,
      stop: Infinity,
    },
  };
  try {
    for (const event of events) {
      transitionCalculators(
        event,
        stateData,
        runningCalculators[sessionId] || {},
        commonCalculators
      );
    }
    store.commit("addRunningCalculators", {
      sessionId,
      calculators: { ...runningCalculators[sessionId] },
      common: false,
    });
  } catch (e) {
    return false;
  }
  return true;
}

export function onStudentLinkStorageUpdate(
  extensionId,
  { key, oldValue, newValue }
) {
  if (key === "selectedSessionId") {
    store.commit("setExtensionSelectedSessionId", newValue);
  } else if (!newValue) {
    // Ignore null values
  } else if (key === "logger") {
    store.commit("setLogger", { logger: newValue, extensionId });
  } else if (key.startsWith("asyncCommandStatus")) {
    const commandType = key.split("/")[1];
    const commandId = key.split("/")[2];
    const payload = {
      compositeId: {
        commandType,
        commandId,
      },
      newVal: newValue,
    };
    console.log("asyncCommandStatus", newValue, payload);
    store.commit("setAsyncCommandStatus", payload);
  } else if (key.startsWith("loggerSession/")) {
    store.commit("addLoggerSession", loggerSessionFromJson(newValue));
  } else if (key.startsWith("events/")) {
    console.log("new events", newValue);

    const sessionId = key.split("/")[1];
    const batchId = key.split("/")[2];
    const events = newValue.map(eventFromJson);

    for (const event of events) {
      const secrets = event.redact(redact);
      // Redact the event and store the secrets in a cache
      Object.keys(secrets).forEach((key) => {
        secretsCache[sessionId] ||= {};
        secretsCache[sessionId][key] = secrets[key];
      });
    }
    // Store the events in a cache using a debounce, collecting as many events
    // as possible and giving a short delay before attempting to transition
    // the calculators.  This prevents us from transitioning the calculators
    // too early, before all events have been received and can be sorted by
    // timestamp.
    eventCache[sessionId] ||= {};
    eventCache[sessionId][batchId] ||= events;

    clearEventCache();
  } else if (key.startsWith("secrets/")) {
    const sessionId = key.split("/")[1];
    const payload = {
      sessionId,
      secrets: newValue,
    };
    store.commit("addSecrets", payload);
  } else if (key.startsWith("keysDeleted/")) {
    const sessionId = key.split("/")[1];
    const payload = {
      sessionId,
      keysDeleted: newValue,
    };
    store.commit("addKeysDeleted", payload);
  } else if (key.startsWith("userSiteData/")) {
    //TODO: Remove this when collaborative Y.js research notebook / notes are fully implemented
    const sessionId = key.split("/")[1];
    const hashedUrl = key.split("/")[2];
    const payload = {
      sessionId,
      hashedUrl,
      value: new UserSiteData(newValue),
    };
    store.commit("addUserSiteData", payload);
  } else if (key.startsWith("assignment/")) {
    store.commit("addAssignment", new Assignment(newValue));
  } else if (key.startsWith("assignmentDetail/")) {
    store.commit("addAssignmentDetail", new AssignmentDetail(newValue));
  }
}
