export const organizationsBreadcrumbs = [
  {
    label: "Home",
    toName: "OrganizationsView",
    toQuery: { disable_redirect: true },
  },
];

// Use the portal breadcrumbs if they are not the last item in the breadcrumbs array
export const organizationPortalBreadcrumbs = [
  ...organizationsBreadcrumbs,
  {
    dynamicLabel: "organization",
    toName: "OrganizationPortal",
    onlyShowIf: (org, course, section, assignment) => {
      return !!org;
    },
  },
];

export const organizationBreadcrumbs = [
  ...organizationsBreadcrumbs,
  {
    dynamicLabel: "organization",
    onlyShowIf: (org, course, section, assignment) => {
      return !!org;
    },
  },
];
