function lazyLoad(view) {
  return () => import(`@/views/assignmentForm/${view}.vue`);
}
export default {
  path: "/assignmentForm",
  children: [
    {
      path: "lti",
      name: "NewLtiAssignmentFormView",
      component: lazyLoad("LtiAssignmentFormView"),
    },

    {
      path: "classroom",
      name: "NewClassroomAssignmentFormView",
      component: lazyLoad("ClassroomAssignmentFormView"),
    },
  ],
};
