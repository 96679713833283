function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "note-boards",
  name: "StudentAssignmentNoteBoardsRoot",
  component: lazyLoad("StudentAssignmentNoteBoardsRoot"),
  redirect: "./",
  props: true,

  children: [
    {
      path: "",
      name: "StudentAssignmentNoteBoardsPortal",
      component: lazyLoad("StudentAssignmentNoteBoardsPortal"),
      props: true,
    },
    {
      path: "list",
      name: "StudentAssignmentNoteBoardsView",
      component: lazyLoad("StudentAssignmentNoteBoardsView"),
      props: true,
    },
  ],
};
