import studentAssignmentRoute from "./student/route";
import teacherAssignmentRoute from "./teacher/route";
import { assignmentBreadcrumbs } from "./routeBreadcrumbs";

function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: "assignments/:assignmentId",
  component: lazyLoad("AssignmentRoot"),
  redirect: "./",
  props: true,
  meta: {
    breadcrumbs: assignmentBreadcrumbs,
  },
  children: [
    {
      path: "",
      name: "AssignmentPortal",
      component: lazyLoad("AssignmentPortal"),
    },
    /** @deprecated - Prefer the AssignmentPortal route at the root of the assignment route. */
    {
      path: "s",
      redirect: "./",
    },
    studentAssignmentRoute,
    teacherAssignmentRoute,
  ],
};
