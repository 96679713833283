import { onStudentLinkStorageUpdate } from "@/services/onStudentLinkStorageUpdate";
import { ExtensionController } from "@/services/ExtensionController";

export const TEACHER_LINK_EXTENSION_ID = "nhdacjmkhimeohpnahfmdmcknaneeffk";
export const ONE_CLICK_EXTENSION_ID = "jlhemnnjmgaaaklbcdpiafnkgibjnoed";
export const STUDENT_LINK_EXTENSION_ID = "hlpffihikpginaficljilffbkeanfcpg";

export const ALL_EXTENSION_IDS = [
  STUDENT_LINK_EXTENSION_ID,
  TEACHER_LINK_EXTENSION_ID,
  ONE_CLICK_EXTENSION_ID,
];

const oneClick = new ExtensionController(ONE_CLICK_EXTENSION_ID);
const studentLink = new ExtensionController(STUDENT_LINK_EXTENSION_ID);

oneClick.addStorageChangedListener(onStudentLinkStorageUpdate);
studentLink.addStorageChangedListener(onStudentLinkStorageUpdate);

export const oneClickController = oneClick;
export const studentLinkController = studentLink;
export const teacherLinkController = new ExtensionController(
  TEACHER_LINK_EXTENSION_ID
);

export function getAllExtensionControllers() {
  return [studentLink, teacherLinkController, oneClick];
}

export function getSomeActiveExtensionController() {
  return getAllExtensionControllers().find((c) => c.manifest);
}

export function getExtensionControllerById(extensionId) {
  return getAllExtensionControllers().find(
    (c) => c.extensionId === extensionId
  );
}

export async function broadcastMessage(data, filter = (controller) => true) {
  let resp;
  try {
    const controllers = getAllExtensionControllers().filter(
      (controller) => !!controller.manifest && filter(controller)
    );

    resp = await Promise.allSettled(
      controllers.map((c) => c.sendMessage(data))
    );
  } catch (error) {
    console.log("Error broadcasting message:", error);
  }
  return resp;
}

export async function broadcastMessageUntilResponse(
  data,
  filter = (controller) => true
) {
  const controllers = getAllExtensionControllers().filter(
    (c) => !!c.manifest && filter(c)
  );
  for (let controller of controllers) {
    try {
      const resp = await controller.sendMessage(data);
      return resp;
    } catch (error) {
      console.log("Error broadcasting message:", error);
    }
  }
  return null;
}
